import "../App.css";
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ConnectWallet, useAddress, Web3Button } from "@thirdweb-dev/react";

import { ethers } from "ethers";

import { ClipLoader } from "react-spinners";
import { allowed } from "../utils/Vouchers.js";
import { allowed2 } from "../utils/Vouchers2.js";

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import axios from 'axios';

function App() {
  /* global BigInt */
  const navigate = useNavigate();
  const location = useLocation();
  const address = useAddress();
  const totalSupply = 5000;
  const [voucher, setVoucher] = useState(null);
  const [inpreSale1, setInPresale1] = useState(null);
  const [inpreSale2, setInPresale2] = useState(null);
  const voucherPublic = "0x37ec949ed383d16fc2b15a9c3767fab66b122c8a2e017534b3f85bd1bc7f98d174418e0d8320d0199d073bd83ad7aac4354a8377104422fc99351bf86cbbfc471b";
  const [eligible, setEligible] = useState(false);
  const [showCrossmintPopup, setShowCrossmintPopup] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [phase, setPhase] = useState("publicsale");
  const [COST_ETH, setCOST_ETH] = useState(0.1); //change
  const [phaseNumber, setPhaseNumber] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [totalMintedPhase, setTotalMintedPhase] = useState(null);
  const [totalMintedPhaseCurrent, setTotalMintedPhaseCurrent] = useState(0);
  const [totalAddress, setTotalAddress] = useState(0);
  const [maxMint, setMaxMint] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [connected, setIsConnected] = useState(false);
  const [isCreditCard, setIsCreditCard] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTx, setShowTx] = useState(false);
  const [countdown, setCountdown] = useState("");
  const [tx, setTx] = useState(null);

  const isSoldOut = totalMinted >= totalSupply;

  const [ethPrice, setEthPrice] = useState(null);

  const fetchEthPrice = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
      setEthPrice(response.data.ethereum.usd);
    } catch (error) {
      console.error("Error fetching ETH price:", error);
    }
  };

  useEffect(() => {
    fetchEthPrice();
  }, []);

  const lockScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const unlockScroll = () => {
    document.body.style.overflow = "";
  };

  const calculateTimeLeft = () => {
    const phaseInfo = phases[phase];
    if (!phaseInfo) {
      return {};
    }
  
    // Use UTC time for consistency
    const now = new Date().getTime();
    const startTime = phaseInfo.startTime;
    const endTime = phaseInfo.endTime;
    let difference = 0;
  
    if (now < startTime) {
      // Phase has not started yet
      difference = startTime - now;
    } else if (now <= endTime) {
      // Phase is ongoing
      difference = endTime - now;
    }
  
    // Calculate time left in user's local time zone
    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  
    return timeLeft;
  };
  

  const formatTimeAbbreviated = (timeLeft) => {
    const days = timeLeft.days || 0;
    const hours = timeLeft.hours || 0;
    const minutes = timeLeft.minutes || 0;
    const seconds = timeLeft.seconds || 0;

    const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    return formattedTime;
  };

  const phases = {
    presale1: {
      startTime: new Date("December 21, 2023 16:00:00 UTC").getTime(),
      endTime: new Date("December 21, 2023 21:00:00 UTC").getTime(),
    },
    presale2: {
      startTime: new Date("December 22, 2023 17:00:00 UTC").getTime(),
      endTime: new Date("December 22, 2023 17:00:00 UTC").getTime(),
    },
    publicsale: {
      startTime: new Date("December 22, 2023 17:00:00 UTC").getTime(),
      endTime: new Date("December 25, 2023 12:00:00 UTC").getTime(),
    },
  };

  useEffect(() => {
    if (loading) {
      lockScroll();
    } else {
      unlockScroll();
    }

    return () => unlockScroll();
  }, [loading]);

  function CrossMintModal({ onClose }) {

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-box">
          <p className="modal-message">
          Your credit card purchase will appear in another window. You can refresh this page once completed.<br></br><br></br><b>Once you complete your purchase, check your email for how to access your NFTs.</b>
          </p>
          <div className="modal-buttons">
            <a href="/" >
              <button className="modal-btn">Refresh</button>
            </a>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (phase === "presale1") {
      setCOST_ETH(0.06);
      setPhaseNumber(0);
    } else if (phase === "presale2") {
      setCOST_ETH(0.08);
      setPhaseNumber(0);
    } else {
      setCOST_ETH(0.1); //change
      setPhaseNumber(1);
      setVoucher(voucherPublic);
      setEligible(true);
    }
  }, [phase]);
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const phaseParam = searchParams.get("phase");

    if (phaseParam) {
      setPhase(phaseParam);
    }
  }, [location]);

  const creditCard = () => {
    setIsCreditCard(true);
    setIsMinting(true);
    getStatsCC();
  };

  const goBack = () => {
    setIsCreditCard(false);
    setIsMinting(false);
  };

  function TransactionModal({ onClose, tx }) {

    const handleModalClick = (e) => {
      e.stopPropagation();
    };

    const transactionLink =
      tx && tx.receipt && tx.receipt.transactionHash
        ? `https://etherscan.io/tx/${tx.receipt.transactionHash}`
        : "#";
      
        const nftLink = "https://megadethdigital.io/profile/wallet";

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-box" onClick={handleModalClick}>
          <button className="modal-close-btn" onClick={onClose}>
            X
          </button>
          <h2 className="modal-title">Congratulations!</h2>
          <p className="modal-message">
            You are officially a part of the Megadeth Digital Community!
          </p>
          <div className="modal-buttons">
            <a href={nftLink} target="_blank">
              <button className="modal-btn">View NFT</button>
            </a>
            <a href={transactionLink} target="_blank" rel="noopener noreferrer">
              <button className="modal-btn" disabled={!tx}>
                View Transaction
              </button>
            </a>
            <a href="https://discord.gg/megadeth" target="_blank">
              <button className="modal-btn">Join Discord</button>
            </a>
          </div>
        </div>
      </div>
    );
  }

  function SubmittingTx() {
    return (
      <div className="modal-overlay">
        <div className="modal-box">
          <h2 className="modal-title">Transaction Initiated!</h2>
          <p className="modal-message">
            Please do not refresh your page
            <span className="loading-dots"></span>
          </p>
        </div>
      </div>
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
    getStats(address);
  };

  useEffect(() => {
    getMinted();
  }, []);

  useEffect(() => {
    getStats(address);
    setIsConnected(true);
    setIsMinting(true);
  }, [address]);

  const previousAddressRef = useRef();
  useEffect(() => {
    previousAddressRef.current = address;
  }, [address]);
  const previousAddress = previousAddressRef.current;

  useEffect(() => {
    if (previousAddress && !address) {
      navigate("/");
    }
  }, [address, previousAddress]);

  const contractRead = {
    address: "0xC609596bd470353D1b5Fa6479a1eaC360112BB56",
    abi: [
      "function mint(address wallet, uint256 amount, MintType mintType) external payable",
      "function getAmountMintedPerType(uint8 mintType, address _address) public view returns (uint256)",
      "function balanceOf(address account) external view returns (uint256)",
      "function mintActive(uint8) view returns (bool)",
      "function mintMax(uint8) view returns (uint256)",
      "function allowlistMinted() public view returns (uint256)",
      "function totalSupply() external view returns (uint256)",
    ],
  };

  async function getMinted() {
      const RPC =
        "https://eth-mainnet.g.alchemy.com/v2/5_mV5h_0btXckVN8zVvI9PsK7gN9S_uc";
      const provider = new ethers.providers.JsonRpcProvider(RPC);

      const CONTRACT_ADDRESS = new ethers.Contract(
        contractRead.address,
        contractRead.abi,
        provider
      );

      var totalMinted = await CONTRACT_ADDRESS.totalSupply();

      var mintedTotal = Number(BigInt(totalMinted));

      setTotalMinted(mintedTotal);

  }

  async function getStats(address) {
    setLoading(true);

    try {
      const RPC =
        "https://eth-mainnet.g.alchemy.com/v2/5_mV5h_0btXckVN8zVvI9PsK7gN9S_uc";
      const provider = new ethers.providers.JsonRpcProvider(RPC);

      const CONTRACT_ADDRESS = new ethers.Contract(
        contractRead.address,
        contractRead.abi,
        provider
      );

      var mintedInPhase = await CONTRACT_ADDRESS.getAmountMintedPerType(
        phaseNumber,
        address
      );

      const balance = await provider.getBalance(address);
      setUserBalance(ethers.utils.formatEther(balance));

      var mintedInPhaseTotal = Number(BigInt(mintedInPhase));

      var totalMinted = await CONTRACT_ADDRESS.totalSupply();

      var mintedTotal = Number(BigInt(totalMinted));

      var totalMintedAddrerss = await CONTRACT_ADDRESS.balanceOf(address);

      var mintedTotalAddress = Number(BigInt(totalMintedAddrerss));

      setTotalAddress(mintedTotalAddress);

      const isActive = await CONTRACT_ADDRESS.mintActive(phaseNumber);

      const maxMintForPhase = await CONTRACT_ADDRESS.mintMax(phaseNumber);
      const maxMintForPhaseNumber = Number(maxMintForPhase);

      setMaxMint(maxMintForPhaseNumber);

      const remainingMintable = maxMintForPhaseNumber - mintedInPhaseTotal;
      setTotalMintedPhase(remainingMintable > 0 ? remainingMintable : 0);
      setTotalMintedPhaseCurrent(mintedInPhaseTotal);

      setIsActive(isActive);

      setTotalMinted(mintedTotal);

      const mapping = allowed[address.toLowerCase()];
      const mapping2 = allowed2[address.toLowerCase()];

      if(mapping) {
        setInPresale1(true)
      }
      else{
        setInPresale1(false)
      }

      
      if(mapping2) {
        setInPresale2(true)
      }
      else{
        setInPresale2(false)
      }

      if (phase === "presale1") {
        if (mapping) {
          setVoucher(mapping);
          setEligible(true);
        } else {
            if(phase !== "publicsale") setEligible(false);
        }
      } else {
        if (mapping2) {
          setVoucher(mapping2);
          setEligible(true);
        } else {
          if(phase !== "publicsale") setEligible(false);
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  async function getStatsCC() {
    setLoading(true);

    try {
      const RPC =
        "https://eth-mainnet.g.alchemy.com/v2/5_mV5h_0btXckVN8zVvI9PsK7gN9S_uc";
      const provider = new ethers.providers.JsonRpcProvider(RPC);

      const CONTRACT_ADDRESS = new ethers.Contract(
        contractRead.address,
        contractRead.abi,
        provider
      );

      var totalMinted = await CONTRACT_ADDRESS.totalSupply();

      var mintedTotal = Number(BigInt(totalMinted));

      const isActive = await CONTRACT_ADDRESS.mintActive(phaseNumber);

      const maxMintForPhase = await CONTRACT_ADDRESS.mintMax(phaseNumber);
      const maxMintForPhaseNumber = Number(maxMintForPhase);

      setMaxMint(maxMintForPhaseNumber);

      setIsActive(isActive);

      setTotalMinted(mintedTotal);

      setEligible(true);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const incrementMintAmount = () => {
    if(totalMintedPhase === null){
      setTotalMintedPhase(5);
    }
    setMintAmount((prev) => (prev < totalMintedPhase ? prev + 1 : prev));
  };


  const decrementMintAmount = () => {
    if (mintAmount > 1) {
      setMintAmount((prev) => prev - 1);
    }
  };

  return (
    <main className="page-wrapper">
      <header className="header navbar navbar-expand-lg bg-secondary shadow-sm shadow-dark-mode-none">
        <div className="container px-3">
          <a
            href="https://megadethdigital.io/"
            className="navbar-brand pe-3"
          >
            <img src="logo.png" width={47} alt="Logo" />
          </a>
          <div id="navbarNav" className="offcanvas offcanvas-end">
            <div className="offcanvas-header border-bottom">
              <h5 className="offcanvas-title">Menu</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    href="https://megadethdigital.io/"
                    className="nav-link text-nowrap"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-nowrap active"
                    style={{ color: "#16f100" }}
                  >
                    Mint
                  </a>
                </li>
              </ul>
            </div>
            <div className="offcanvas-header border-top"></div>
          </div>
          <div className="form-check form-switch mode-switch pe-lg-1 ms-auto me-4">
            <a
              href="https://twitter.com/megadethdigital"
              target="_blank"
              className="btn btn-icon btn-secondary btn-twitter rounded-circle mx-1 "
            >
              <i className="bx bxl-twitter"></i>
            </a>
            <a
              href="https://discord.gg/megadeth"
              target="_blank"
              className="btn btn-icon btn-secondary btn-discord rounded-circle mx-1 "
            >
              <i className="bx bxl-discord"></i>
            </a>
          </div>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </header>

      {loading && (
        <div className="loading-container">
          <ClipLoader size={150} color={"#16f100"} loading={loading} />
        </div>
      )}

      
{isSoldOut ? (
         <section id="module" className="mint-page-day">
         <div
           id="connect-wallet"
           className="row justify-content-center text-center pb-4 mb-2 mint-module"
         >
           <div className="col-xl-12 col-lg-9 col-md-10 py-4">
             <img
               src="logo.png"
               width={200}
               alt="Logo"
               className="py-3 centered-image"
             />
                  {/* <div className="mint-text-small">
                   <b>Cyber Army Presale:</b> Max 2 (0.06 ETH)
                 </div>
                 <div className="mint-text-small">December 21st, 12pm EST - 4pm EST</div>
                 <br></br>
                 <div className="mint-text-small">
                   <b>Dethlist Presale:</b> Max 2 (0.08 ETH)
                 </div>
                 <div className="mint-text-small">December 21st, 4pm EST until 1,000 sold or 12pm on December 22nd</div>
                 <br></br> */}
                 <div className="mint-text-small">
                   <b>Public Sale is live</b>
                 </div>
                 <div className="mint-text-small">December 22nd, 12pm EST until sold out</div>
                 <br></br>

                 {phase === "publicsale" ? (
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: 5,
                          padding: 20,
                        }}
                      >
                        <div style={{ color: "black" }} className="mint-text-small">
                          This public sale phase is sold out.
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "white",
                          borderRadius: 5,
                          padding: 20,
                        }}
                      >


                        <div style={{ color: "black" }} className="mint-text-small">
                          This phase is sold out. Public sale will begin soon.
                        </div>
                        <br></br>
                        {/* <div style={{color:"black"}} className="mint-text-small">
                        Public Sale starts in <b>{countdown}</b>
                        </div> */}
                      </div>

                     )}
 
         </div>
         </div>
       </section>
      ) : (

      <section id="module" className="mint-page-day">
        <div
          id="connect-wallet"
          className="row justify-content-center text-center pb-4 mb-2 mint-module"
        >
          <div className="col-xl-12 col-lg-9 col-md-10 py-4">
            <img
              src="logo.png"
              width={200}
              alt="Logo"
              className="py-3 centered-image"
            />

            {!address && !isCreditCard && (
              <>
                  {/* <div className="mint-text-small">
                   <b>Cyber Army Presale:</b> Max 2 (0.06 ETH)
                 </div>
                 <div className="mint-text-small">December 21st, 12pm EST - 4pm EST</div>
                 <br></br>
                 <div className="mint-text-small">
                   <b>Dethlist Presale:</b> Max 2 (0.08 ETH)
                 </div>
                 <div className="mint-text-small">December 21st, 4pm EST until 1,000 sold or 12pm on December 22nd</div>
                 <br></br> */}
                 {/* <div className="mint-text-small">
                 <b>Public Sale is live</b>
                 </div> */}
                 {/* <br></br> */}
                     {/* <div
                            style={{
                              marginBottom: 20,
                              width: "400px",
                              display: "flex",       // Set display to flex
                              alignItems: "center",  // Align items vertically in the center
                              justifyContent: "center", // Center items horizontally
                              margin: "0 auto" 
                          }}
                    className="progress-bar-container"
                  >
                    <progress value={totalMinted} max={totalSupply}></progress>
                  </div> */}
                  {/* <div
                       style={{
                        marginTop: 20,
                        width: "400px",
                        display: "flex",       // Set display to flex
                        alignItems: "center",  // Align items vertically in the center
                        justifyContent: "center", // Center items horizontally
                        margin: "0 auto"       // Automatically adjust margin to center the div
                    }}
                    className="progress-bar-container"
                  >
                    <span>
                      {totalMinted} / {totalSupply} minted
                    </span>
                  </div> */}
                <br></br>
              </>
            )}

            {!address && phase === "publicsale" && !isCreditCard && (
              <div style={{ borderRadius: 5 }}>
                <div className="mint-text-small">
                  Do you have an Ethereum wallet?
                </div>
              </div>
            )}

            {!isCreditCard && (
              <ConnectWallet
                btnTitle={
                  phase === "publicsale"
                    ? "Yes, I have a wallet"
                    : "Connect Wallet"
                }
                className="connect"
                style={{ width: 225 }}
              />
            )}

            {!address && phase === "publicsale" && !isCreditCard && (
              <button
                className="modal-btn"
                style={{ fontWeight: 600, width: 225 }}
                onClick={() => (isCreditCard ? goBack() : creditCard())}
              >
                {isCreditCard ? "Go Back" : "I do not have a wallet"}
              </button>
            )}

            {(address || isCreditCard) && !loading && (
              <div style={{ marginTop: 10 }}>
                {/* <div className="mint-text-small">
                  Phase:
                  <b>
                    {phase === "presale1" && " Cyber Army Presale"}
                    {phase === "presale2" && " Dethlist Presale"}
                    {phase === "publicsale" && " Public Sale"}
                  </b>
                </div> */}

{/* //change */}

            <div className="mint-text-small">
              Cost: {ethPrice && `$${parseFloat(ethPrice*0.1).toFixed(2)} USD`}  (0.1 ETH) 
            </div>



                <div style={{ marginBottom: 10 }} className="mint-text-small">
                  Max Mint:&nbsp;
                  <b>{maxMint}</b>
                </div>
                {phase !== "publicsale" && (
                  <div className="mint-text-small">
                    Eligible:
                    <b style={{ color: eligible ? "#16f100" : "red" }}>
                      {eligible ? " Yes" : " No"}
                    </b>
                  </div>
                )}

                {eligible && (totalMintedPhase > 0 || totalMintedPhase === null) && (
                  <div className="number-input-container">
                    <button onClick={decrementMintAmount}>-</button>
                    <input type="number" value={mintAmount} readOnly />
                    <button onClick={incrementMintAmount}>+</button>
                  </div>
                )}


                {eligible && !isCreditCard && (
                  <>
                    {/* <div style={{ marginTop: 10 }} className="mint-text-small">
                      You have minted <b>{totalMintedPhaseCurrent}</b> in this
                      phase
                    </div> */}
                    <div style={{ marginTop: 10 }} className="mint-text-small">
                      You can mint <b>{totalMintedPhase}</b> more tokens
                    </div>

                    <div
                      style={{ marginTop: 10, marginBottom: 20 }}
                      className="mint-text-small"
                    >
                      You have minted <b>{totalAddress}</b> tokens
                    </div>
                  </>
                )}
{/* 
                {eligible && (
                  <div
                    style={{ marginTop: 20 }}
                    className="progress-bar-container"
                  >
                    <progress value={totalMinted} max={totalSupply}></progress>
                    <span>
                      {totalMinted} / {totalSupply} minted
                    </span>
                  </div>
                )} */}

                {eligible && (totalMintedPhase > 0 || totalMintedPhase === null) && (
                  <>
                    {!isCreditCard && (
                      <Web3Button
                        style={{
                          marginTop: 20,
                          width: 225,
                          opacity: isActive ? 1 : 0.5,
                          pointerEvents: isActive ? "auto" : "none",
                        }}
                        contractAddress="0xC609596bd470353D1b5Fa6479a1eaC360112BB56"
                        action={async (contract) => {
                          var ether = COST_ETH * mintAmount;
    
                          ether = Number(ether.toFixed(2));
                      
                          var parsedEther = ethers.utils.parseEther(ether.toString());

                          if (parseFloat(userBalance) < ether) {
                            alert(
                              "You do not have enough ETH to cover the transaction."
                            );
                            return;
                          } else {
                            setShowTx(true);

                            try {
                              const txResponse = await contract.call(
                                "mint",
                                [address, voucher, Number(mintAmount), phaseNumber],
                                { value: parsedEther }
                              );
                              console.log(txResponse);
                              setTx(txResponse);
                              setShowModal(true);
                            } catch (error) {
                              console.error("Minting failed:", error);
                            }
                            setShowTx(false);
                          }
                        }}
                      >
                        Buy {mintAmount} {mintAmount > 1 ? "Tokens" : "Token"}{" "}
                        with ETH
                      </Web3Button>
                    )}

                    {isMinting ? (
                      <div
                        className="connect"
                        style={{
                          marginTop: 20,
                          opacity: isActive ? 1 : 0.5,
                          pointerEvents: isActive ? "auto" : "none",
                        }}
                      >
                        <CrossmintPayButton
                          onClick={() => setShowCrossmintPopup(true)}
                          collectionId="3781d967-8a8b-436b-acb9-8e878023aeb0"
                projectId="79c00172-2582-47c7-8f72-92952dc5bf57"
                          mintConfig={{
                            totalPrice: String(COST_ETH * mintAmount),
                            voucher: voucher,
                            amount: mintAmount,
                            mintType: phaseNumber,
                          }}
                          mintTo={address}
                        />
                      </div>
                    ) : (
                      <div
                        className="connect"
                        style={{
                          marginTop: 20,
                          opacity: isActive ? 1 : 0.5,
                          pointerEvents: isActive ? "auto" : "none",
                        }}
                      >
                        <CrossmintPayButton
                          onClick={() => setShowCrossmintPopup(true)}
                          collectionId="3781d967-8a8b-436b-acb9-8e878023aeb0"
                          projectId="79c00172-2582-47c7-8f72-92952dc5bf57"
                          mintConfig={{
                            totalPrice: String(COST_ETH * mintAmount),
                            voucher: voucher,
                            amount: mintAmount,
                            mintType: phaseNumber,
                          }}
                        />
                      </div>
                    )}


                    {!isActive && <p style={{marginTop:20}}>Phase is not active yet</p>}
                  </>
                )}

                {!address && phase === "publicsale" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                  >
                    {isCreditCard && (
                  <button
                        className="modal-btn-back"
                        style={{ fontWeight: 600, width: 225 }}
                        onClick={goBack}
                      >
                        Go Back
                      </button>
                    
                    )}
                  </div>
                )}
              </div>
            )}

            {showModal && (
              <TransactionModal onClose={handleCloseModal} tx={tx} />
            )}
             {showCrossmintPopup && (
              <CrossMintModal />
            )}
            {showTx && <SubmittingTx />}

            {address && !loading && !eligible && phase !== "publicsale" && (
              <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    display: "block",
                    marginTop: 20,
                    padding: 20,
                  }}
                >
                  {(!inpreSale1 && inpreSale2) ? (
                    <div style={{ color: "black" }} className="mint-text-small">
                      You are not eligible for this presale. <b>You can mint in the Dethlist presale (12/21 @ 4pm EST)</b></div>
                  ) : (!inpreSale1 && !inpreSale2) ? (
                    <div style={{ color: "black" }} className="mint-text-small">
                      You are not eligible for the presale. <b>You will be able to mint in the public sale (12/22 @ 12pm EST)</b></div>
                  ) : null}
                </div>
            )}
          </div>

          <div className="col-xl-8 col-lg-9 col-md-10">
            <a
              href="https://etherscan.io/address/0xC609596bd470353D1b5Fa6479a1eaC360112BB56"
              target="_blank"
              className="btn btn-icon btn-secondary btn-facebook rounded-circle mx-1 d-lg-inline-flex"
            >
              <img style={{ height: "20px" }} src="etherscan.png"></img>
            </a>

            <a
              href="https://rarible.com/megadethdigital/items"
              target="_blank"
              className="btn btn-icon btn-secondary btn-facebook rounded-circle mx-1 d-lg-inline-flex"
            >
              <img style={{ height: "20px" }} src="rarible.png"></img>
            </a>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-10  py-3">
            By minting you agree to the <a href="https://megadethdigital.io/legal" target="_blank" >terms and conditions</a>.
          </div>
        </div>
      </section>
  )}

      <div className="powered-by">
        Powered by{" "}
        <a
          style={{ textDecoration: "none" }}
          href="https://niftylabs.dev/"
          target="_blank"
        >
          NiftyLabs
        </a>
      </div>
    </main>
  );
}

export default App;
